import React, { useState, useRef, useEffect } from "react"
import logo from "../../assets/images/Logo.png"
import axios from "axios"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Badge,
  Spinner,
} from "reactstrap"
import cellEditFactory from "react-bootstrap-table2-editor"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import withRouter from "../../components/Common/withRouter"
//i18next
import { connect } from "react-redux"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import { Link } from "react-router-dom"
import {
  postSubmitForm,
} from "../../helpers/backend_helper"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Search_Bar from "../../helpers/Search_Bar"
import PromotionalGoods from "./promotionalGoods"
import Flatpickr from "react-flatpickr"
import DeliveredPromotionalGoods from "./DeliveredPromotionalGoods"
import PromotionalGoodsFinalTable from "./PromotionalGoodsFinalTable"
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter"
import BackgroundImage from "../../assets/images/Background.png"
import preventValueChangeOnScroll from "../../helpers/PreventValueOnScroll"

const AllPurchaseOrder = props => {
  const { SearchBar } = Search
  const [POItems, setPOItems] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [printModal, setPrintModal] = useState()
  const [editModal, setEditModal] = useState(false)
  const [selectedRow, setselected_Row] = useState()
  const [row2, setRow2] = useState({})
  const [userToken, setUserToken] = useState(localStorage.getItem("userToken"))
  const [receiveBtnClick, setReceiveBtnClick] = useState(0)
  const [deliveryItemArr, setDeliveryItemArr] = useState([])
  const [freeItemsModal, setFreeItemsModal] = useState(false)
  const [results, setResults] = useState([])
  const [selectedFreeItems, setSelectedFreeItems] = useState([])
  const [cameWithRow, setCameWithRow] = useState({})
  const [promotionalGoodsArr, setPromotionalGoods] = useState({})
  const [singleItemId, setSingleItemId] = useState({})
  const [selectedPO, setSelectedPO] = useState([])
  const [promoItemsVisible, setPromoItemsVisible] = useState(
    promotionalGoodsArr.length > 0 || false
  )
  const [selectedItemRow, setSelectedItemRow] = useState({})
  const [validationError, setValidationError] = useState(false)
  const [addedItems, setAddedItems] = useState({})
  // const [promoTableStatus, setPromoTableStatus] = useState({})
  const [promoTableDeliveredStatus, setPromoTableDeliveredStatus] = useState({})
  const [po_openedTracker, setPO_OpenedTracker] = useState(0)
  const [promoItemsValidationError, setPromoItemsValidationError] =
    useState(false)
  const [finalPromoItemsArr, setFinalPromoItemsArr] = useState([])
  const [expiryDate, setExpiryDate] = useState("")
  const [rowIndex, setRowIndex] = useState([])
  const [document_file, set_document_file] = useState()
  const [deliveredPromoItems, setDeliveredPromoItems] = useState([])
  const [uniqueCode, setUniqueCode] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isPOSLoading, setIsPOSLoading] = useState(false)
  const [receiveItemsLoader, setReceiveItemsLoader] = useState(false)
  const [bool, setBool] = useState(false)
  const [minDateVal, setMinDateVal] = useState("");
  const [fromdate, setFromdate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
  const [todate, setTodate] = useState(new Date())
  let grandTotal = 0
  let grandTotalForOrdered = 0
  const object = {
    from_date: moment(fromdate).format("YYYY-MM-DD"),
    to_date: moment(todate).format("YYYY-MM-DD")
}

  const handleValidSubmit = async () => {
    loadAllOrder(object);
}
  useEffect(() => {
    if (validationError === true) {
      setReceiveItemsLoader(prev => (prev = false))
    }
  }, [bool])

  document.title = "All Purchase Order | Rendezvous - Game Parlour"

  useEffect(() => {
    const object = {
      from_date: moment(fromdate).format("YYYY-MM-DD"),
      to_date: moment(todate).format("YYYY-MM-DD")
  }
    if (selectedRow) {
      let promoItemsArr = selectedRow.delivery_details.map(item => {
        // let promoItemsObj = {}
        for (let i = 0; i < item.promotional_goods.length; i++) {
          item.promotional_goods[i].cameWith = item.name
        }
        return item.promotional_goods
      })
      let tempPromoItemsArr = []
      for (let i = 0; i < promoItemsArr.length; i++) {
        tempPromoItemsArr = [...tempPromoItemsArr, ...promoItemsArr[i]]
      }
      setDeliveredPromoItems(tempPromoItemsArr)
    }

    if (po_openedTracker > 0) {
      if (selectedPO.delivery_details) {
        let a = selectedPO.delivery_details.map(item => item.promotional_goods)

        let b = []
        for (let i = 0; i < a.length; i++) {
          b = [...b, ...a[i]]
        }

        if (b.length > 0) {
          // setAddPromoItemsBtnVisible(prev => prev = { ...prev, [selectedPO._id]: true })
          setPromoTableDeliveredStatus(
            prev => (prev = { ...prev, [selectedPO._id]: true })
          )
        }
      }
    }
    if (receiveBtnClick > 0) {
      setPromotionalGoods(prev => (prev = { ...prev, [selectedPO._id]: [] }))
      setFinalPromoItemsArr(prev => (prev = []))
      // setPromoTableStatus(prev => prev = { ...prev, [selectedPO._id]: false })
      loadAllOrder(object)
      return
    }
    loadAllOrder(object)
  }, [
    receiveBtnClick,
    po_openedTracker,
    selectedRow,
    // expiryDate
  ])

  function handleCheck() {
    setPO_OpenedTracker(prev => (prev += 1))
  }

  function editFormatter(cell, row) {
    return (
      <span className="text-info d-flex">
        <i
          className="bx bxs-file fs-5 ms-0"
          aria-hidden="true"
          title="Click to Receive"
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => {
            setEditModal(prev => (prev = !prev))
            setSelectedPO(row)
            let arr = JSON.parse(JSON.stringify(row.items))
            setRow2(row.items)
            setPOItems(prev => (prev = [...row.items]))
            setInvoiceNumber()
            setDeliveryItemArr(row.delivery_details)
            handleCheck()
            setValidationError(prev => (prev = false))
          }}
        ></i>

        {row.po_status === "Delivered" || row.po_status === "Partially Delivered" ? (
          <i
            className="fa fa-print fs-5 ms-3 " aria-hidden="true"
            style={{ cursor: "pointer", color: "black" }}
            title={"Click to Print Delivered Items"}
            onClick={() => {
              setPrintModal(prev => (prev = !prev))
              setselected_Row(row)
              setSelectedPO(row)
            }}
          ></i>
        ) : null}
        <i
          className="fa fa-envelope fs-5 ms-3"
          aria-hidden="true"
          title="Send Email"
          style={{ cursor: "pointer", color: "red" }}
          onClick={() => {
            // handleValidDelete(row)
          }}
        ></i>

        {row.po_status === "Partially Delivered" || row.po_status === "Delivered" || row.po_status === "Pending" ? (
          <i
            className="fa fa-times fs-5 ms-3"
            title={"Click to Close PO"}
            style={{ cursor: row.po_status === "Closed" ? "not-allowed" : "pointer", color: "red" }}
            onClick={() => { row.po_status === "Closed" ? showNotification("Already Closed", "Error") : row.po_status === "Partially Delivered" || row.po_status === "Delivered" || row.po_status === "Pending" ? closePurchaseOrder(row.po_id) : null }}
          />
        ) : null}



      </span>
    )
  }

  function freeGoodsFormatter(cell, row) {
    return (
      <>
        {selectedPO.po_status !== "Delivered" &&
          selectedPO.po_status !== "Closed" ? (
          <i
            className="bx bxs-edit fs-5 ms-3 "
            title="Click to Add Promotional Items"
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              setFreeItemsModal(prev => (prev = !prev))
              setPromoItemsVisible(true)
              setSelectedItemRow(row)
              // setCameWithRow(prev => prev = {...prev, [row._id] : row });
              setCameWithRow(row.name)
              // setSingleItemId(row._id)
            }}
          ></i>
        ) : (
          ""
        )}
      </>
    )
  }

  //getAll
  const [allOrder, setAll_Order] = useState([])

  const loadAllOrder = async (payload) => {
   try {
    setIsPOSLoading(true)
    let url = process.env.REACT_APP_BASEURL + "purchase_orders/get_all_orders"
    let response = await postSubmitForm(url, payload)
    if (response.status === 1) {
    setAll_Order(response.data)
    setIsLoading(prev => (prev = false))
    setIsPOSLoading(false)
    } else {
      setIsLoading(prev => (prev = false))
      setIsPOSLoading(false)
      showNotification(response.message, "Error")
    }
   } catch (error) {
    console.log(error);
    setIsLoading(prev => (prev = false))
    setIsPOSLoading(false)
   }
  }

  function closePurchaseOrder(po_id) {
    let url = process.env.REACT_APP_BASEURL + "purchase_orders/close_order"
    let payload = { po_id: po_id }

    axios
      .post(url, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then(res => {
        showNotification(
          res.data.message,
          res.data.status === 0 ? "Error" : "Success"
        )
        setReceiveBtnClick(prev => (prev += 1))
      })
      .catch(err => console.log(err))
  }

  // ----------------------------------------------------------------------------------------------------------

  function expiryDateFormatter(cell, row, rowIndex) {
    return (
      <>
        {
          row.is_expiry_date === false ? (
            <p>No Expiry</p>
          ) : (
            <Flatpickr
              style={{
                border: "1px solid lightGray",
                borderRadius: "5px",
                backgroundColor: "rgb(248 249 250)",
                width: "100px",
              }}
              options={{
                minDate: "today",
              }}
              onChange={date => {
                setExpiryDate(
                  prev => (prev = moment(date[0]).format("YYYY-MM-DD"))
                )
                setRowIndex(prev => (prev = rowIndex))
                row.expiry_date = moment(date[0]).format("YYYY-MM-DD")
              }}
            />
          )

          //   <i
          //   className="fa fa-times fs-5 ms-3 "
          //   title="Click to remove date"
          //   style={{ cursor: "pointer", color: "blue" }}
          //   onClick={()=> setCalendarDate(prev => prev = "")}

          // ></i>
        }
      </>
    )
  }
  // -------------------------------------------------------------------------------------------------------------
 const [orderDetails, setOrderDetails] = useState()
 const [printOrderItems, setPrintOrderItems] = useState(false)
  function invoiceFormatter(cell, row) {
    return (
    <>
        <i
          style={{ color: "blue", cursor: "pointer" }}
          title={"Click to Print Ordered Items "}
          onClick={() => {
            grandTotalForOrdered = 0
            setOrderDetails(row)
            setPrintOrderItems(true)
          }}
        >{row.po_id}
        </i>
</>
    );
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("#"),
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },

      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: "Po ID",
      dataField: "po_id",
      formatter: invoiceFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      sort: true,
    },
    {
      text: props.t("Supplier"),
      dataField: "supplier.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Branch"),
      dataField: "branch_details.name",
      sort: false,
      filter: selectFilter({
        options: [{ value: "Rendezvous", label: "Rendezvous" }],
        placeholder: "ALL",
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("E-mail"),
      dataField: "supplier.email",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Mobile"),
      dataField: "supplier.mobile",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Address"),
      dataField: "supplier.address",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Date"),
      dataField: "createdAt",
      sort: true,
      formatter: (cell, row) => {
        const timestamp = row.createdAt
        const formattedDate = moment(timestamp).format("YYYY-MM-DD")
        return formattedDate
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Status"),
      formatter: (cell, row) => {
        return (
          // <p>pending</p>
          <Badge
            className={`font-size-12 badge-soft-${row.po_status === "Pending"
              ? "danger"
              : row.po_status === "Delivered"
                ? "success"
                : row.po_status === "Partially Delivered"
                  ? "warning"
                  : null
              }`}
          >
            {row.po_status}
          </Badge>
        )
      },
      dataField: "po_status",
      sort: false,
      filter: selectFilter({
        options: [
          { value: "Pending", label: "Pending" },
          { value: "Delivered", label: "Delivered" },
          { value: "Partially Delivered", label: "Partially Delivered" },
          { value: "Closed", label: "Closed" },
        ],
        defaultValue: "Pending",
        placeholder: "ALL",
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Total Price"),
      dataField: "items_total_price",
      formatter: (cell, row) => {
        return <p>{row.items_total_price.toFixed(2)}</p>
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
  ]

  //Insert Delivery Details
  const SubmitDeliveryDetails = async (selectedPO, items) => {
    // filter(item => +(item.receive.in_unit) !== 0 || +(item.receive.in_sub_unit) !== 0)
    const payload = {
      id: selectedPO._id,
      // invoice_image: document_file,
      delivery_details: items
        .filter(
          item =>
            +item.receive.in_unit !== 0 ||
            +item.receive.in_sub_unit !== 0 ||
            (item.is_expiry_date === true &&
              item.expiry_date !== null &&
              item.expiry_date !== "")
        )
        .map(item => {
          let delivery_obj = {
            item_id: item._id,
            name: item.name,
            section: item.section,
            unit: item.unit,
            sub_unit: item.sub_unit,
            formula: item.formula,
            category: item.category,
            threshold: +item.threshold,
            is_menu_item: item.is_menu_item,
            is_for_sale: item.is_for_sale,
            is_for_use:item.is_for_use,
            is_household_items:item.is_household_items,
            last_threshold_reached:item.last_threshold_reached,
            price: +item.cost_price,
            selling_price: item.selling_price
              ? +item.selling_price
              : +item.price,
            total_price: +item.total_price,
            receive: {
              in_unit: +item.receive.in_unit,
              in_sub_unit: +item.receive.in_sub_unit,
            },
            quantity: {
              in_unit: +item.quantity.in_unit,
              in_sub_unit: +item.quantity.in_sub_unit,
            },
            is_expiry_date: item.is_expiry_date,
            expiry_date: item.expiry_date ? item.expiry_date : "",
            invoice_number: invoiceNumber,
            is_partial: item.quantity.in_unit > item.receive.in_unit,
            promotional_goods:
              finalPromoItemsArr[selectedPO._id]
                ?.filter(promoItems => {
                  return promoItems.cameWith === item.name
                })
                .map(promoItems => {
                  return {
                    item_id: promoItems._id,
                    name: promoItems.name,
                    section: promoItems.section,
                    unit: promoItems.unit,
                    sub_unit: promoItems.sub_unit,
                    formula: +promoItems.formula,
                    category: promoItems.category,
                    threshold: +promoItems.threshold,
                    is_menu_item: item.is_menu_item,
                    is_for_sale: item.is_for_sale,
                    is_for_use:item.is_for_use,
                    is_household_items:item.is_household_items,
                    last_threshold_reached:item.last_threshold_reached,
                    price: 0,
                    total_price: 0,
                    receive: {
                      in_unit: +promoItems.receive.in_unit,
                      in_sub_unit: +promoItems.receive.in_sub_unit,
                    },
                    is_expiry_date: item.is_expiry_date,
                    expiry_date: promoItems.expiry_date || "",
                  }
                }) || [],
          }
          return delivery_obj
        }),
    }
    // return
    if (payload.delivery_details.length > 0) {
      payload.delivery_details.forEach(item => {
        if (payload.delivery_details.length > 0) {
          if (item.is_expiry_date === true) {
            if (
              item.expiry_date === "" ||
              (+item.receive.in_unit === 0 && +item.receive.in_sub_unit === 0)
            ) {
              // console.log("hello 1")
              setValidationError(true)
              setBool(prev => (prev = !prev))
              return
            } else {
              setValidationError(false)
            }
          } else {
            // console.log("false");

            if (
              +item.receive.in_unit === 0 &&
              +item.receive.in_sub_unit === 0
            ) {
              // console.log("hello 2")
              setValidationError(true)
              setBool(prev => (prev = !prev))
              return
            } else {
              setValidationError(false)
            }
          }
        }
      })
    } else {
      // console.log("hello 3")
      setBool(prev => (prev = !prev))
      setValidationError(prev => (prev = true))
      return
    }

    const formData = new FormData()
    formData.set("id", selectedPO._id)
    if (document_file) {
      formData.append('document_file', document_file);
   
    }
    formData.set("delivery_details",
      JSON.stringify(items.filter(
        item =>
          +item.receive.in_unit !== 0 ||
          +item.receive.in_sub_unit !== 0
      ).map(item => {
        let delivery_obj = {

          item_id: item._id,
          name: item.name,
          section: item.section,
          unit: item.unit,
          sub_unit: item.sub_unit,
          formula: item.formula,
          is_menu_item: item.is_menu_item,
          is_for_sale: item.is_for_sale,
          is_for_use:item.is_for_use,
          is_household_items:item.is_household_items,
          last_threshold_reached:item.last_threshold_reached,
          category: item.category,
          threshold: +(item.threshold),
          price: +(item.cost_price),
          selling_price: item.selling_price ? +(item.selling_price) : +(item.price),
          total_price: +(item.total_price),
          receive: {
            in_unit: +(item.receive.in_unit) || 0,
            in_sub_unit: +(item.receive.in_sub_unit) || 0,
          },
          quantity: {
            in_unit: +(item.quantity.in_unit),
            in_sub_unit: +(item.quantity.in_sub_unit)
          },
          expiry_date: item.expiry_date ? item.expiry_date : null,
          invoice_number: invoiceNumber,
          // invoice_image_url: ,
          is_partial: item.quantity.in_unit > item.receive.in_unit,
          promotional_goods: finalPromoItemsArr[selectedPO._id]?.filter((promoItems) => {
            return promoItems.cameWith === item.name;
          }).map((promoItems) => {
            return {
              item_id: promoItems._id,
              name: promoItems.name,
              section: promoItems.section,
              unit: promoItems.unit,
              sub_unit: promoItems.sub_unit,
              formula: +(promoItems.formula),
              category: promoItems.category,
              threshold: +(promoItems.threshold),
              is_menu_item: item.is_menu_item,
              is_for_sale: item.is_for_sale,
              is_for_use:item.is_for_use,
              is_household_items:item.is_household_items,
              last_threshold_reached:item.last_threshold_reached,
              price: 0,
              total_price: 0,
              receive: {
                in_unit: +(promoItems.receive.in_unit),
                in_sub_unit: +(promoItems.receive.in_sub_unit)
              },
              expiry_date: promoItems.expiry_date || null
            };
          }) || []


        }; return delivery_obj
      }))
    );
// console.log(items,"Ite");
// console.log("delivery_details", JSON.parse(formData.get("delivery_details")));
// console.log(payload,"Pl");
// return

    let url = process.env.REACT_APP_BASEURL

    axios
      .post(
        url + `purchase_orders/receive_items`,

        formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(res => {
        setReceiveItemsLoader(prev => (prev = false))
        if (res.data.status === 0) {
          showNotification(res.data.message,"Error")
          return
        } else {
          showNotification(
            res.data.message,
            res.data.status === 0 ? "Error" : "Success"
          )
          setEditModal(prev => (prev = !prev))
          setReceiveBtnClick(prev => (prev = prev + 1))
        }

      })
      .catch(err => {
        setReceiveItemsLoader(prev => (prev = false))
        showNotification(err.response?.data?.message,"Error")
        console.log(err,"errrr")
      })
  }


  const ReceiveItems = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      text: props.t("#"),
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      // sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Section"),
      dataField: "section",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      // sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    // {
    //   text: props.t("Unit"),
    //   dataField: "unit",
    //   // sort: true,
    //   editable: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "2%" }
    //   },
    // },
    // {
    //   text: props.t("Sub-Unit"),
    //   dataField: "sub_unit",
    //   formatter: (cell, row) => {
    //     return (
    //       <p>
    //         {row.sub_unit === null || row.sub_unit === "" ? (
    //           <Badge className="badge-soft-danger">No Sub-Unit</Badge>
    //         ) : (
    //           `${row.sub_unit}`
    //         )}
    //       </p>
    //     )
    //   },
    //   // sort: true,
    //   editable: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "2%" }
    //   },
    // },
    {
      text: props.t("Formula"),
      dataField: "formula",
      // sort: true,
      editable: false,
      formatter: (cell, row) => {
        return (
          <>
            <span>1 {row.unit}</span>{" "}
            <span>
              {row.sub_unit && row.formula
                ? `= ${row.formula} ${row.sub_unit}`
                : ""}
            </span>
          </>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },
    },
    // {
    //   text: props.t("Quantity In Unit"),
    //   formatter: (cell, row) => {
    //     return <p>{`${row.quantity.in_unit} ${row.unit}`}</p>
    //   },
    //   dataField: "quantity.in_unit",
    //   sort: true,
    //   editable: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },
    // {
    //   text: props.t("Quantity In Sub Unit"),
    //   formatter: (cell, row) => {
    //     return <p>{`${row.quantity.in_sub_unit} ${row.sub_unit}`}</p>
    //   },
    //   dataField: "quantity.in_sub_unit",
    //   sort: true,
    //   editable: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },
    {
      dataField: "quantity.in_unit",
      text: "Order Qty.",
      sort:true,
      formatter: (cell, row) => {
        return (
          <>
            {row.quantity?.in_unit +
              "  " +
              row.unit +
              " " +
              (row.sub_unit && row.sub_unit != "undefined" && row.quantity.in_sub_unit > 0
                ? row.quantity.in_sub_unit + " " + row.sub_unit
                : "")}
          </>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "15%" }
      }
    },
    {
      text: props.t("Cost Price"),
      dataField: "cost_price",
      validator: (newValue, row, column) => {
        if (+newValue === 0) {
          localStorage.setItem("cost_price_validation", true)
          return {
            valid: false,
            message: "can't be zero",
          }
        }
        if (+newValue < 0) {
          localStorage.setItem("cost_price_validation", true)
          return {
            valid: false,
            message: "can't be less than zero",
          }
        }

        localStorage.setItem("cost_price_validation", false)
        return true
      },
      // sort: true,
      editable: true,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (

        <input
          style={{
            width: "50px",
            height: "40px",
            border: "1px solid lightGray",
            padding: "5px",
            borderRadius: "5px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          type="number"
          defaultValue={value}
          onBlur={(e) => {
            handlePriceChange(e, rowIndex, row, editorProps)
          }}
          onFocus={(e) => e.target.select()} // Retain focus on input field
          autoFocus
        />

      ),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Selling Price"),
      dataField: "selling_price",
      formatter: (cell, row) => {
        return <p>{row.selling_price <= 0 ? row.price : row.selling_price}</p>
      },
      validator: (newValue, row, column) => {
        if (+newValue < 0) {
          // localStorage.setItem("selling_price_validation", true)
          return {
            valid: false,
            message: "can't be less than zero.",
          }
        }
        // localStorage.setItem("selling_price_validation", false)
        return true
      },
      // sort: true,
      editable: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Total"),
      dataField: "total_price",
      formatter: (cell, row) => {
        return <p>{row.total_price.toFixed(2)}</p>
      },
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "6%" }
      },
    },
    {
      text: props.t("In Unit"),
      dataField: "receive.in_unit",
      formatter: (cell, row) => {
        return (
          <p
            style={{
              color: +row.receive.in_unit === 0 ? "#E57373" : "",
              fontWeight: +row.receive.in_unit === 0 ? 600 : 0,
              // width: "50px",
            }}
          >{`${row.receive.in_unit} ${row.unit}`}</p>
        )
      },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <input
          onWheel={preventValueChangeOnScroll}
          style={{
            width: "50px",
            height: "40px",
            border: "1px solid lightGray",
            padding: "5px",
            borderRadius: "5px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          type="number"
          border={`1px solid ${value === 0 ? "red" : "lightGray"}`}
          defaultValue={value}
          // onChange={(e) => handleQuantityChange(e, rowIndex,row,editorProps)}
          onBlur={e => {
            handleQuantityChange(e, rowIndex, row, editorProps);
            editorProps.onUpdate(+e.target.value)
            // setEnterQtyArr(prev => { prev = { ...prev, [row._id]: row.quantity.in_unit === 0 ? false : true } })
          }}
          onFocus={e => e.target.select()} // Retain focus on input field
          autoFocus
        />
      ),
      validator: (newValue, row, column) => {
        // if (+newValue > +row.quantity.in_unit) {
        //   return {
        //     valid: false,
        //     message: "cannot exceed ordered quantity",
        //   }
        // }

        if (+newValue < 0) {
          // localStorage.setItem("in_unit_validation", true)
          return {
            valid: false,
            message: "Unit can't be less than zero.",
          }
        }
        if (+newValue % 1 != 0) {
          // localStorage.setItem("in_unit_validation", true)
          return {
            valid: false,
            message: "Decimals not allowed.",
          }
        }

        // localStorage.setItem("in_unit_validation", false)
        return true
      },
      // sort: true,
      editable: true,
      attrs: (cell, row, rowIndex, colIndex) => {
        return { title: "Click to Edit" }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      // style: (cell, row, rowIndex, colIndex) => {
      //   return { cursor: "pointer", background: "#ffffaa" };
      // },
    },
    {
      text: props.t("In Sub-Unit"),
      dataField: "receive.in_sub_unit",
      // formatter: (cell, row) => {
      //   return <p style={{ color: +row.receive.in_sub_unit === 0 ? "#E57373" : "", fontWeight: +row.receive.in_sub_unit === 0 ? 600 : 0, width: "50px" }} >{`${row.receive.in_sub_unit} ${row.sub_unit}`}</p>;
      // },
      formatter: (cell, row) => {
        return (
          <p
            style={{
              color: row.receive.in_sub_unit === 0 ? "#E57373" : "",
              fontWeight: row.receive.in_sub_unit === 0 ? 600 : 0,
              // width: "50px",
            }}
          >
            {row.sub_unit === "" || row.sub_unit === null ? (
              <i className="fa fa-ban" />
            ) : (
              `${row.receive.in_sub_unit} ${row.sub_unit}`
            )}
          </p>
        )
      },
      // sort: true,
      editable: (cell, row) =>
        row.sub_unit === "" || row.sub_unit === null ? false : true,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <input
          onWheel={preventValueChangeOnScroll}
          style={{
            width: "50px",
            height: "40px",
            border: "1px solid lightGray",
            padding: "5px",
            borderRadius: "5px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          type="number"
          border={`1px solid ${value === 0 ? "red" : "lightGray"}`}
          defaultValue={value}
          // onChange={(e) => handleQuantityChange(e, rowIndex,row,editorProps)}
          onBlur={e => {
            handleSubUnitQuantityChange(e, rowIndex, row, editorProps);
            // editorProps.onUpdate(+e.target.value)
            // setEnterQtyArr(prev => { prev = { ...prev, [row._id]: row.quantity.in_unit === 0 ? false : true } })
          }}
          onFocus={e => e.target.select()} // Retain focus on input field
          autoFocus
        />
      ),
      validator: (newValue, row, column) => {
        const newSubUnitValue = +newValue;
        const formula = +row.formula;
        const availableUnits = +row.quantity.in_unit;
        const availableSubUnits = +row.quantity.in_sub_unit;
        const totalAvailableSubUnits = (formula * availableUnits) + availableSubUnits;
        const receivedUnits = +row.receive.in_unit;
        const receivedSubUnits = +row.receive.in_sub_unit;
        const totalReceivedSubUnits = (receivedUnits * formula) + newSubUnitValue;
      
        // Validation checks
        // if (totalReceivedSubUnits > totalAvailableSubUnits) {
        //   return {
        //     valid: false,
        //     message: "Cannot exceed available quantity.",
        //   };
        // }
        if (+newValue < 0) {
          return {
            valid: false,
            message: "Sub-Unit can't be less than zero.",
          }
        }
        if (+newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          }
        }
        return true
      },
      attrs: (cell, row, rowIndex, colIndex) => {
        return { title: "Click to Edit" }
      },
      // style: (cell, row, rowIndex, colIndex) => {
      //   return { cursor: "pointer", background: "#ffffaa" };
      // },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Expiry Date"),
      // dataField: "expiry_date",
      sort: true,
      editable: false,
      formatter: expiryDateFormatter,

      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Free Items"),
      dataField: "free_items",
      // sort: true,
      editable: false,
      formatter: freeGoodsFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "6%" }
      },
    },
  ]

  const handleSubUnitQuantityChange = (e, rowIndex, row, editorProps) => {
    const { value } = e.target;
    const updatedData = { ...row };
    updatedData.receive.in_sub_unit = +value;
    
    let subUnits = 0;
    let units = 0;
    
    if (+value >= Number(row.formula)) {
      subUnits = +value % Number(row.formula);
      units = Math.floor(+value / Number(row.formula));
    } else {
      subUnits = +value;
    }
    
    editorProps.onUpdate(subUnits);
    
    let a = [...row2]; 
    
    a.forEach(item => {
      if (item._id === row._id) {
        if (+value >= +row.formula) {
          item.receive.in_unit += units;
          item.receive.in_sub_unit = subUnits;
          item.total_price = ((item.receive.in_unit * Number(row.formula) + subUnits) * (row.cost_price / Number(row.formula)));
        } else {
          item.total_price = ((item.receive.in_unit * Number(row.formula) + subUnits) * (row.cost_price / Number(row.formula)));
        }
      }
    });
  
    setRow2([...a]); 
  };
  

  const handleQuantityChange = (e, rowIndex, row, editorProps) => {
    const { value } = e.target
    const updatedData = { ...row }
    const rowData = updatedData
    rowData.receive.in_unit = +value

    editorProps.onUpdate(+value)

    let a = row2

    if (row.sub_unit === "" || row.sub_unit === null) {

      a.forEach(item => {
        if (item._id === row._id) {
          item.total_price =
            (+value) * (rowData.cost_price)
        }
      })
    } else {
      a.forEach(item => {
        if (item._id === row._id) {
          item.total_price =
            (+value * Number(rowData.formula) +
              Number(rowData.receive.in_sub_unit)) *
            (rowData.cost_price / rowData.formula)
        }
      })
    }

    setRow2(prev => {
      prev = [...a]
      return prev
    })
  }

  const handlePriceChange = (e, rowIndex, row, editorProps) => {

    const { value } = e.target;
    const updatedData = { ...row };
    const rowData = updatedData;
    rowData.price = +value;

    editorProps.onUpdate(+value);

    let a = row2;
    if (row.sub_unit === "" || row.sub_unit === null) {

      a.forEach(item => {
        if (item._id === row._id) {

          item.total_price = +value * rowData.receive.in_unit
        }
      })
    } else {
      a.forEach(item => {
        if (item._id === row._id) {
          item.total_price = +value / rowData.formula * ((Number(rowData.receive.in_unit) * Number(rowData.formula)) + Number(rowData.receive.in_sub_unit))
        }
      })
    }
    setRow2(prev => { prev = [...a]; return prev })
  };
  // ---------------------------------------------------------------------------------------------------------------

  function viewInvoiceFormatter(cell, row) {
    return (
      <>
      {row.invoice_image_url && (
          <i
            className="bx bxs-file fs-5 ms-0"
            aria-hidden="true"
            title="Click to View Invoice"
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              openPDF(row)
            }}
          />
      )}
        <p>{row.invoice_number}</p>
      </>
    )
  }

  // delivery details table's column
  const deliveryColumns = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      text: props.t("#"),
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      // sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Section"),
      dataField: "section",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    // {
    //   text: props.t("Category"),
    //   dataField: "category",
    //   // sort: true,
    //   editable: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },
    // {
    //   text: props.t("Unit"),
    //   dataField: "unit",
    //   // sort: true,
    //   editable: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "2%" }
    //   },
    // },
    // {
    //   text: props.t("Sub-Unit"),
    //   dataField: "sub_unit",
    //   formatter: (cell, row) => {
    //     return (
    //       <p>
    //         {row.sub_unit === null || row.sub_unit === "" ? (
    //           <Badge className="badge-soft-danger">No Sub-Unit</Badge>
    //         ) : (
    //           `${row.sub_unit}`
    //         )}
    //       </p>
    //     )
    //   },
    //   // sort: true,
    //   editable: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "2%" }
    //   },
    // },
    {
      text: props.t("Formula"),
      dataField: "formula",
      // sort: true,
      editable: false,
      formatter: (cell, row) => {
        return (
          <>
            <span>1{row.unit}</span>{" "}
            <span>
              {row.sub_unit && row.formula
                ? `= ${row.formula} ${row.sub_unit}`
                : ""}
            </span>
          </>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },
    },
    {
      dataField: "quantity.in_unit",
      text: "Order Qty.",
      sort:true,
      formatter: (cell, row) => {
        return (
          <>
            {row.quantity?.in_unit +
              "  " +
              row.unit +
              " " +
              (row.sub_unit && row.sub_unit != "undefined" && row.quantity.in_sub_unit > 0
                ? row.quantity.in_sub_unit + " " + row.sub_unit
                : "")}
          </>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      }
    },
    {
      text: props.t("Cost Price"),
      dataField: "price",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    // {
    //   text: props.t("Selling Price"),
    //   dataField: "selling_price",
    //   sort: true,
    //   editable: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },
    {
      text: props.t("Total"),
      dataField: "total_price",
      formatter: (cell, row) => {
        return <p>{row.total_price.toFixed(2)}</p>
      },
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "receive.in_unit",
      text: "Rec. Qty.",
      sort:true,
      formatter: (cell, row) => {
        return (
          <>
           <p style={{color:"red"}}>  {row.receive?.in_unit +
              "  " +
              row.unit +
              " " +
              (row.sub_unit && row.sub_unit != "undefined" && row.receive.in_sub_unit > 0
                ? row.receive.in_sub_unit + " " + row.sub_unit
                : "")}</p>
          </>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      }
    },

    {
      text: props.t("Expiry Date"),
      dataField: "expiry_date",
      formatter: (cell, row) => {
        return row.expiry_date !== null ? (
          <p>{row.expiry_date}</p>
        ) : (
          <p>No Expiry</p>
        )
      },
      sort: true,
      editable: false,
      // formatter: (cell, row) => {
      //   const timestamp = row.expiry_date;
      //   const formattedDate = moment(timestamp).format('DD-MM-YYYY');
      //   return formattedDate;
      // },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Invoice Number/PDF"),
      formatter: viewInvoiceFormatter,
      // sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
  ]
  // --------------------------------------------------------------------------------

  const handleDocumentChange = event => {
    const file = event.target.files[0]
    set_document_file(file)
  }
  // --------------------------------------------------------------------------

  function handleAddPromotionalGoods() {
    let flag = false
    // console.log(promotionalGoodsArr[selectedPO._id].length, "promo good array length")
    if (promotionalGoodsArr[selectedPO._id]?.length > 0) {
      promotionalGoodsArr[selectedPO._id]?.forEach(item => {
        if (item.is_expiry_date === true) {
          if (
            item.expiry_date === "" ||
            (item.receive.in_unit === 0 && item.receive.in_sub_unit === 0)
          ) {
            setPromoItemsValidationError(prev => (prev = true))

            flag = true
            return
          } else if (flag === false) {
            setPromoItemsValidationError(prev => (prev = false))

            flag = false
          }
        } else {
          if (item.receive.in_unit === 0 && item.receive.in_sub_unit === 0) {
            setPromoItemsValidationError(prev => (prev = true))

            flag = true
            return
          } else if (flag === false) {
            setPromoItemsValidationError(prev => (prev = false))

            flag = false
          }
        }
      })

      if (flag === false) {
        setFinalPromoItemsArr(
          prev =>
          (prev = {
            ...prev,
            [selectedPO._id]: prev[selectedPO._id]
              ? [
                ...prev[selectedPO._id],
                ...promotionalGoodsArr[selectedPO._id],
              ]
              : promotionalGoodsArr[selectedPO._id],
          })
        )
        setPromotionalGoods(prev => (prev = []))
        showNotification("Promotional Items Added", "Success")
        setPromoItemsValidationError(prev => (prev = false))
      }
    } else {
      setPromoItemsValidationError(prev => (prev = true))
      showNotification("Add Some Promotional Items First", "Error")
      return
    }
  }

  const openPDF = row => {
    // console.log(row.invoice_image_url, "invoice pdf")
    if (row.invoice_image_url) {
      window.open(row.invoice_image_url, "_blank")
    }
  }
  // -----------------------------------------------------------

  const printDiv = divName => {
    let content = document.getElementById(divName)
    var printWindow = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    )
    printWindow.document.write(`
   
       <html>
       <title>${divName}</title>
       <head> 
        <style>
        table {
          table-layout: fixed;
          font-family: "Poppins", sans-serif;
          border: 1px solid #eff2f7;
          font-size: 12px;
          border-collapse: collapse;
          max-width: 100%;
          color: #495057;
        
          margin-bottom: 1rem;
          margin-top: 1rem;
        }
        
        for-print-heading {
          font-size: 16px;
          margin: 0 0 0 0;
          font-weight: bold;
        }

        for-print-sub_heading {
          font-size: 14px;
          margin: 0 0 0 0;
          font-weight: normal !important;
        }
        table td, table th {
          border: 1px solid #ddd;
          padding: 8px;
        }
        
        table tr:nth-child(even){background-color: #f2f2f2;}
        
        table tr:hover {background-color: #ddd;}
        
        table th {            
          text-align: left;
        }
         .head{
          display:flex;
          justify-content:space-between;
         }
        </style>
       </head>
    <body>
  ${content.innerHTML}
    </body>
       </html>

    `)
    printWindow.document.close()
    printWindow.print()
    printWindow.focus()
  }

  const expandRow = {
    onlyOneExpanding: true,
    renderer: row => (
      <>
        {/* {row.po_status === "Partially Delivered" && ( */}
        <Card >
          <div className="row "  >
            <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
              {row.items.length > 0 && (
                <CardBody>
                  <div className="table-responsive ">
                    <CardTitle>
                      <h5>Requested Items</h5>
                    </CardTitle>
                    <table
                      id="example"
                      className="table table-bordered"
                      style={{ width: "100%" }}
                    >
                      <thead className="bg-light">
                        <tr>

                          <th>S.N</th>
                          <th>Name</th>
                          <th>Requested Quantity</th>
                          <th>Sub Total(QR)</th>

                        </tr>
                      </thead>
                      <tbody style={{ backgroundColor: "white" }}>
                        {row?.items.map((item, index) =>

                          <tr key={index + 1} >
                            <td>{index + 1}</td>
                            <td>{item?.name}</td>
                            <td>{item.quantity.in_unit} &nbsp; {item.unit}&nbsp; {(item.quantity.in_sub_unit ? item.quantity.in_sub_unit : null)} &nbsp; {item.quantity.in_sub_unit ? item.sub_unit : ""} </td>
                            <td>{item?.total_price.toFixed(2)}</td>
                          </tr>

                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              )}

              {row.delivery_details.length > 0 && (
                <CardBody>
                  <div className="table-responsive ">
                    <CardTitle>
                      <h5>Received Items</h5>
                    </CardTitle>
                    <table
                      id="example"
                      className="table table-bordered"
                      style={{ width: "100%" }}
                    >
                      <thead className="bg-light">
                        <tr>
                          <th>S.N</th>
                          <th>Name</th>
                          <th>Received Quantity</th>
                          <th>Received date</th>
                          <th>Sub Total(QR)</th>
                        </tr>
                      </thead>
                      <tbody style={{ backgroundColor: "white" }}>
                        {row?.delivery_details.map((item, index) => (
                          <tr key={index + 1}>
                            <td>{index + 1}</td>
                            <td>{item?.name}</td>
                            <td>
                              {item.receive.in_unit} &nbsp; {item.unit} &nbsp;{" "}
                              {item.receive.in_sub_unit
                                ? item.receive.in_sub_unit
                                : null}{" "}
                              &nbsp; {item.sub_unit}{" "}
                            </td>
                            <td>
                              {moment(item?.received_date).format("DD-MM-YYYY")}
                            </td>
                            <td>{item?.total_price.toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              )}
            </div>
          </div>
        </Card>
        {/* )} */}
      </>
    ),
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title="Inventory"
            breadcrumbItem1="All Purchase Order"
            alternateTitle="All Purchase Order"
          />
           <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <AvForm onValidSubmit={handleValidSubmit}>
                    <Row >


                      <Col lg={3}>
                        <label style={{ color: "white" }} className="form-label">
                          {props.t("From Date")}
                        </label>

                        <AvField
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={fromdate}
                          options={{

                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener('mousedown', (e) => {
                                e.preventDefault(); // Prevent default click behavior
                              });
                            },
                          }}
                          onChange={(v) => {
                            const dateString = v;
                            const date = new Date(dateString);
                            setFromdate(date);
                            const day = date.getDate();
                            const month = date.getMonth() + 1;
                            const year = date.getFullYear();
                            const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                            setMinDateVal(formattedDate);
                          }}
                          name="from_date"
                          placeholder="Select From Date"
                          errorMessage="Select From Date."
                          validate={{
                            required: { value: true },
                          }}
                        />

                      </Col>

                      <Col lg={3} >

                        <label style={{ color: "white" }} className="form-label">
                          {props.t("To Date")}
                        </label>
                        <AvField
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={todate}
                          onChange={(v) => {
                            const dateString = v;
                            const date = new Date(dateString);
                            setTodate(date);
                          }}
                          options={{
                            minDate: minDateVal,
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener('mousedown', (e) => {
                                e.preventDefault();
                              });
                            },

                          }}
                          name="to_date"
                          placeholder="Select To Date"
                          errorMessage="Select To Date."
                          validate={{
                            required: { value: true },
                          }}
                        />


                      </Col>

                      <Col lg={2}>
                        <div
                          style={{
                            marginTop: "25px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            type="submit"
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "#fdd005",
                              border: "none",
                              color: "black",

                              width: "100px",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                            disabled={isPOSLoading}
                          >
                          {isPOSLoading?<Spinner size={"sm"}/> : (
                          <>{props.t("Submit")}</>
                          )}
                            
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>
                        </div>
                      </Col>

                    </Row>

                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>{props.t("View all Purchase Order")} </CardTitle>
                  {isLoading === true ? (
                    <Spinner style={{ display: "block", margin: "auto" }} />
                  ) : (
                    <ToolkitProvider
                      keyField="_id"
                      data={allOrder && allOrder}
                      columns={columns}
                      search
                    >
                      {propst => (
                        <div>
                          <Row>
                            <Col
                              lg={4}
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <SearchBar
                                className="form-control"
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                                placeholder="search"
                              />
                            </Col>
                          </Row>

                          <BootstrapTable
                            {...propst.baseProps}
                            filter={filterFactory()}
                            noDataIndication={props.t("No data to display.")}
                            striped
                            expandRow={expandRow}
                            hover
                            condensed
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Row>
        <Col>
          <Modal
            size="xl"
            isOpen={editModal}
            toggle={() => {
              loadAllOrder(object)
              setEditModal(prev => (prev = !prev))
            }}
          >
            <ModalHeader
              toggle={() => {
                loadAllOrder(object)
                setEditModal(prev => (prev = !prev))
              }}
            >
              {props.t("Purchase Order Details")}{" "}
              {selectedPO && selectedPO.po_id}
            </ModalHeader>
            <ModalBody>
              {selectedPO.po_status !== "Delivered" && (
                <Card>
                  <CardBody>
                    <CardTitle>{props.t("Items Ordered")} </CardTitle>
                    <AvForm
                      onValidSubmit={() => {
                        SubmitDeliveryDetails(selectedPO, POItems)
                        setReceiveItemsLoader(prev => (prev = true))
                      }}
                    >
                      <ToolkitProvider
                        keyField="_id"
                        data={row2}
                        columns={ReceiveItems}
                      >
                        {propst => (
                          <div>
                            <BootstrapTable
                              {...propst.baseProps}
                              cellEdit={cellEditFactory({
                                mode: "click",
                                blurToSave: true,
                              })}
                              noDataIndication={props.t("No data to display.")}
                              striped
                              hover
                              condensed
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                      {/* {(selectedPO.po_status === "Delivered" || selectedPO.po_status === "Partially Delivered" ) ?  */}
                      {/* <Row>

                            <Col>

                              <Button onClick={openPDF}>
                                Open Invoice PDF
                              </Button>
                            </Col>
                          </Row> : */}

                      <Row style={{ display: "flex" }}>
                        <Col lg={2}>
                          <AvField
                            label="Invoice Number"
                            type="text"
                            name="invoiceNumber"
                            // value={""}
                            placeholder="Invoice Number"
                            onChange={e => {
                              setInvoiceNumber(e.target.value)
                            }}
                            required="true"
                            errorMessage={props.t(
                              "Please Enter Invoice Number"
                            )}
                          />
                        </Col>

                        <Col lg={2}>
                          <AvField
                            label="Upload Invoice PDF"
                            className="btn btn-primary wave-effect btn-sm"
                            name="invoice_pdf"
                            type="file"
                            onChange={e => {
                              handleDocumentChange(e)
                            }}
                            validate={{
                              required: { value: false },
                            }}
                            errorMessage={props.t("Please Upload Invoice PDF")}
                          >
                            {props.t("Upload File")}
                          </AvField>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          lg={12}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {/* <Button
                            disabled={selectedPO.po_status === "Closed" || selectedPO.po_status === "Delivered"}
                            type="submit" className="btn btn-info">
                            Receive Item
                          </Button> */}
                          <Button
                            disabled={
                              selectedPO.po_status === "Closed" ||
                              selectedPO.po_status === "Delivered"
                            }
                            type="submit"
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "#fdd005",
                              border: "none",
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "500",
                              width: "128px",
                              // marginTop: "-0.2rem",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                          >
                            {props.t("Receive Item")}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>
                          {receiveItemsLoader && (
                            <Spinner style={{ marginLeft: "10px" }} />
                          )}
                        </Col>
                      </Row>
                      {validationError && (
                        <Row>
                          <Col
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              color: "red",
                            }}
                          >
                            Please fill in-unit/in sub-unit, expiry date of
                            items to be received.
                          </Col>
                        </Row>
                      )}
                    </AvForm>
                  </CardBody>
                </Card>
              )}

              <Card>
                <CardBody>
                  <hr />
                  <CardTitle>{props.t("Items Received")} </CardTitle>

                  <ToolkitProvider
                    keyField="_id"
                    data={deliveryItemArr}
                    columns={deliveryColumns}
                  >
                    {propst => (
                      <div>
                        <BootstrapTable
                          {...propst.baseProps}
                          cellEdit={cellEditFactory({
                            mode: "click",
                            blurToSave: true,
                          })}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>

              {/* {promoTableStatus[selectedPO._id] && <PromotionalGoods setPromoTableStatus={setPromoTableStatus} promoTableStatus={promoTableStatus} addedItems={addedItems} setAddedItems={setAddedItems} singleItemId={singleItemId}
                //  finalPromoItemsArr={finalPromoItemsArr}
                promotionalGoods={promotionalGoodsArr} setPromotionalGoods={setPromotionalGoods}
                cameWithRow={cameWithRow} selectedPO={selectedPO} />} */}

              {finalPromoItemsArr[selectedPO._id]?.length > 0 && (
                <PromotionalGoodsFinalTable
                  addedItems={addedItems}
                  setAddedItems={setAddedItems}
                  singleItemId={singleItemId}
                  finalPromoItemsArr={finalPromoItemsArr}
                  setFinalPromoItemsArr={setFinalPromoItemsArr}
                  cameWithRow={cameWithRow}
                  selectedPO={selectedPO}
                />
              )}

              {promoTableDeliveredStatus[selectedPO._id] && (
                <DeliveredPromotionalGoods
                  addedItems={addedItems}
                  setAddedItems={setAddedItems}
                  cameWithRow={cameWithRow}
                  selectedPO={selectedPO}
                  deliveredPromoItems={deliveredPromoItems}
                  setDeliveredPromoItems={setDeliveredPromoItems}
                />
              )}
            </ModalBody>
          </Modal>
        </Col>
      </Row>

      {/* <ReceiveItemsModal cameWithRow={cameWithRow} deliveryItemArr={deliveryItemArr} deliveryColumns={deliveryColumns} ReceiveItems={ReceiveItems}
        row2={row2} selectedPO={selectedPO} editModal={editModal} setEditModal={setEditModal} promotionalGoodsArr={promotionalGoodsArr}
      /> */}

    {/* print for Delivered Items  */}
      <Row>
        <Col>
          <Modal
            size="xl"
            isOpen={printModal}
            toggle={() => setPrintModal(prev => (prev = !prev))}
          >
            <ModalHeader
              toggle={() => setPrintModal(prev => (prev = !prev))}
            ></ModalHeader>
            <ModalBody>
              <div id="printDiv">
                {selectedRow && (
                  <div className="container pl-4  pr-0">
                    <div
                      className="row head"
                      style={{
                        backgroundColor: " #f0f0f0",
                        height: "90px",
                        borderRadius: "50px 0px 0px 50px",
                      }}
                    >
                      <div
                        className="col col-md-2 col-sm-2"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <img
                          src={logo}
                          style={{
                            height: "105px",
                            borderRadius: " 50%",
                            width: "110px",
                            backgroundColor: "#000000",
                          }}
                        />
                      </div>
                      <div
                        className="col col-md-10 col-sm-10 pt-3"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <p
                          className=""
                          style={{ color: "#000000", fontWeight: "500" }}
                        >
                          Purchase Order Received <br />
                          PO Id : {selectedRow.po_id}
                          <br />
                          Date:{" "}
                          {moment(selectedRow.createdAt).format("YYYY-MM-DD")}
                        </p>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row ">
                      <h5> Supplier Information:</h5>
                    </div>
                    <hr />
                    <div className="row ">
                      <div className="col col-md-4 col-sm-4">
                        <p>
                          <span style={{ fontWeight: 500 }}>
                            Supplier Name: &nbsp;{" "}
                          </span>
                          {selectedRow.supplier.name}
                        </p>
                      </div>
                      <div className="col col-md-4 col-sm-4">
                        <p>
                          <span style={{ fontWeight: 500 }}>
                            Contact Person: &nbsp;
                          </span>
                          {selectedRow.supplier.contact_person}
                        </p>
                      </div>
                      <div className="col col-md-4 col-sm-4">
                        <p>
                          <span style={{ fontWeight: 500 }}>
                            Phone No. : &nbsp;
                          </span>
                          {selectedRow.supplier.phone}
                        </p>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col col-md-4 col-sm-4">
                        <p>
                          <span style={{ fontWeight: 500 }}>Email: &nbsp;</span>
                          {selectedRow.supplier.email}
                        </p>
                      </div>
                      <div className="col col-md-6 col-sm-6">
                        <p>
                          <span style={{ fontWeight: 500 }}>
                            Address: &nbsp;
                          </span>
                          {selectedRow.supplier.address}
                        </p>
                      </div>
                    </div>
                    <hr />
                  
                    <hr />
                    <div className="row ">
                      <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <div className="table-responsive">
                          <h5>Items Received</h5>
                          <table
                            id="example"
                            className="table table-bordered"
                            style={{ width: "100%" }}
                          >
                            <thead className="bg-light">
                              <tr>
                                <th>S.N</th>
                                {/* <th>Invoice Number</th> */}
                                <th>Name</th>
                                <th>Quantity In Unit</th>
                                <th>Quantity In Sub-Unit</th>
                                <th>Price</th>
                                <th>Sub Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Add the code for calculating and displaying the Grand Total here */}
                              {selectedRow.delivery_details.filter(item => +(item.receive.in_unit) !== 0 || +(item.receive.in_sub_unit) !== 0).map((item, index) => {

                                const rowTotal = item.receive.in_sub_unit !== 0
                                  ? ((item.price / item.formula) * ((item.receive.in_unit * item.formula) + item.receive.in_sub_unit)).toFixed(2)
                                  : (item.price * item.receive.in_unit).toFixed(2);
                                  grandTotalForOrdered += parseFloat(rowTotal);
                                return (
                                  <tr key={index + 1}>
                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{`${item.receive.in_unit} ${item.unit}`}</td>
                                    <td>{`${item.receive.in_sub_unit} ${item.sub_unit}`}</td>
                                    <td>{item.price}</td>
                                    <td>{rowTotal}</td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td
                                  colSpan={5}
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Grand Total
                                </td>
                                <td style={{ fontWeight: "bold" }}>
                                  {grandTotalForOrdered.toFixed(2)}
                                </td>
                              </tr>
                              {/* End of Grand Total code */}
                            </tbody>
                          </table>

                          {/* <Row> */}
                          {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <span> Grand Total: &nbsp;</span><span style={{ color: "black", fontWeight: "bolder" }}>  {selectedRow.items_total_price} </span>
                          </div> */}
                          {/* </Row> */}

                          {deliveredPromoItems.length > 0 && (
                            <>
                              {" "}
                              <h5>Promotional Items Received</h5>
                              <table
                                id="example"
                                className="table table-bordered"
                                style={{ width: "100%" }}
                              >
                                <thead className="bg-light">
                                  <tr>
                                    <th>S.N</th>
                                    <th>Came With</th>
                                    <th>Name</th>
                                    <th>Quantity In Unit</th>
                                    <th>Quantity In Sub-Unit</th>
                                    <th>Price</th>
                                    <th>Total Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {deliveredPromoItems.map((item, index) => (
                                    <tr key={index + 1}>
                                      <td>{index + 1}</td>
                                      <td>{item.cameWith}</td>
                                      <td>{item.name}</td>
                                      <td>{item.receive.in_unit}</td>
                                      <td>{item.receive.in_sub_unit}</td>
                                      <td>{item.price}</td>
                                      <td>{item.total_price}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>{" "}
                            </>
                          )}
                        </div>
                        <hr />
                        {/* <div className="row pl-3">
                          <div className="col col-md-8 col-sm-8 col-lg-8 col-xl-8">
                            <h5> Additional Note</h5>
                            <p>
                              Payment with in 30 days upon delivery of the above
                              items
                            </p>
                          </div>
                          <div className="col col-md-4 col-sm-4 col-lg-4 col-xl-4 text-right pr-4">
                            <h6> AUTHORIZED BY</h6>
                          </div>
                        </div> */}
                        <div
                          className="row mb-5"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Row className="col text-right pr-5">
                <Col>
                  <Link
                    to="#"
                    onClick={() => {
                      printDiv("printDiv")
                    }}
                    className="btn btn-primary"
                  >
                    <i className="fa fa-print" /> Print
                  </Link>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Col>
      </Row>

      {/* Print for Ordered Items  */}
      <Row>
        <Col>
          <Modal
            size="xl"
            isOpen={printOrderItems}
            toggle={() => setPrintOrderItems(false)}
          >
            <ModalHeader
              toggle={() =>  setPrintOrderItems(false)}
            ></ModalHeader>
            <ModalBody>
              <div id="printDiv">
                {orderDetails && (
                  <div className="container pl-4  pr-0">
                    <div
                      className="row head"
                      style={{
                        backgroundColor: " #f0f0f0",
                        height: "90px",
                        borderRadius: "50px 0px 0px 50px",
                      }}
                    >
                      <div
                        className="col col-md-2 col-sm-2"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <img
                          src={logo}
                          style={{
                            height: "105px",
                            borderRadius: " 50%",
                            width: "110px",
                            backgroundColor: "#000000",
                          }}
                        />
                      </div>
                      <div
                        className="col col-md-10 col-sm-10 pt-3"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <p
                          className=""
                          style={{ color: "#000000", fontWeight: "500" }}
                        >
                          Purchase Order <br />
                          PO Id : {orderDetails?.po_id}
                          <br />
                          Date:{" "}
                          {moment(orderDetails?.createdAt).format("YYYY-MM-DD")}
                        </p>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row ">
                      <h5> Supplier Information:</h5>
                    </div>
                    <hr />
                    <div className="row ">
                      <div className="col col-md-4 col-sm-4">
                        <p>
                          <span style={{ fontWeight: 500 }}>
                            Supplier Name: &nbsp;{" "}
                          </span>
                          {orderDetails?.supplier?.name}
                        </p>
                      </div>
                      <div className="col col-md-4 col-sm-4">
                        <p>
                          <span style={{ fontWeight: 500 }}>
                            Contact Person: &nbsp;
                          </span>
                          {orderDetails?.supplier?.contact_person}
                        </p>
                      </div>
                      <div className="col col-md-4 col-sm-4">
                        <p>
                          <span style={{ fontWeight: 500 }}>
                            Phone No. : &nbsp;
                          </span>
                          {orderDetails?.supplier?.phone}
                        </p>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col col-md-4 col-sm-4">
                        <p>
                          <span style={{ fontWeight: 500 }}>Email: &nbsp;</span>
                          {orderDetails?.supplier.email}
                        </p>
                      </div>
                      <div className="col col-md-6 col-sm-6">
                        <p>
                          <span style={{ fontWeight: 500 }}>
                            Address: &nbsp;
                          </span>
                          {orderDetails?.supplier.address}
                        </p>
                      </div>
                    </div>
                    <hr />
                  
               
                    <hr />
                    <div className="row ">
                      <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <div className="table-responsive">
                          <h5>Ordered Items</h5>
                          <table
                            id="example"
                            className="table table-bordered"
                            style={{ width: "100%" }}
                          >
                            <thead className="bg-light">
                              <tr>
                                <th>S.N</th>
                                {/* <th>Invoice Number</th> */}
                                <th>Name</th>
                                <th>Quantity In Unit</th>
                                <th>Quantity In Sub-Unit</th>
                                <th>Price</th>
                                <th>Sub Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Add the code for calculating and displaying the Grand Total here */}
                              {orderDetails?.items.map((item, index) => {

                                const rowTotal = item.quantity.in_sub_unit !== 0
                                  ? ((item.cost_price / item.formula) * ((item.quantity.in_unit * item.formula) + item.quantity.in_sub_unit)).toFixed(2)
                                  : (item.cost_price * item.quantity.in_unit).toFixed(2);
                                grandTotal += parseFloat(rowTotal);
                                return (
                                  <tr key={index + 1}>
                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{`${item.quantity.in_unit} ${item.unit}`}</td>
                                    <td>{item.quantity.in_sub_unit > 0 &&
                                      item.quantity.in_sub_unit + " " +item.sub_unit}
                                   </td>
                                    <td>{item.cost_price}</td>
                                    <td>{item.total_price}</td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td
                                  colSpan={5}
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Grand Total
                                </td>
                                <td style={{ fontWeight: "bold" }}>
                                  {grandTotal.toFixed(2)}
                                </td>
                              </tr>
                              {/* End of Grand Total code */}
                            </tbody>
                          </table>

                        

                    
                        </div>
                        <hr />
                        <div
                          className="row mb-5"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Row className="col text-right pr-5">
                <Col>
                  <Link
                    to="#"
                    onClick={() => {
                      printDiv("printDiv")
                    }}
                    className="btn btn-primary"
                  >
                    <i className="fa fa-print" /> Print
                  </Link>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Col>
      </Row>

      <Row>
        <Col>
          <Modal
            size="xl"
            isOpen={freeItemsModal}
            toggle={() => setFreeItemsModal(prev => (prev = !prev))}
          >
            <ModalHeader
              toggle={() => setFreeItemsModal(prev => (prev = !prev))}
            >
              {`Add Free Items Against - ${selectedItemRow.name}`}
            </ModalHeader>
            <div>
              <ModalBody>
                <Search_Bar
                  uniqueCode={uniqueCode}
                  setUniqueCode={setUniqueCode}
                  addedItems={addedItems}
                  setAddedItems={setAddedItems}
                  setPromotionalGoods={setPromotionalGoods}
                  results={results}
                  selectedFreeItems={selectedFreeItems}
                  setSelectedFreeItems={setSelectedFreeItems}
                  setResults={setResults}
                  userToken={userToken}
                  selectedPO={selectedPO}
                  cameWithRow={cameWithRow}
                />

                {
                  <PromotionalGoods
                    addedItems={addedItems}
                    setAddedItems={setAddedItems}
                    singleItemId={singleItemId}
                    promotionalGoods={promotionalGoodsArr}
                    setPromotionalGoods={setPromotionalGoods}
                    cameWithRow={cameWithRow}
                    selectedPO={selectedPO}
                  />
                }

                {/* <Button className="btn btn-info d-flex m-auto">Add Promotional Goods</Button> */}
                <Row>
                  <Button
                    onClick={handleAddPromotionalGoods}
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      backgroundColor: "#fdd005",
                      border: "none",
                      color: "black",
                      fontSize: "15px",
                      fontWeight: "500",
                      width: "210px",
                      // marginTop: "-0.2rem",
                      margin: "auto",
                      boxShadow:
                        "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                      transition: "transform 0.3s ease",
                    }}
                    onMouseEnter={e => {
                      e.target.style.transform = "scale(1.05)"
                    }}
                    onMouseLeave={e => {
                      e.target.style.transform = "scale(1)"
                    }}
                  >
                    {props.t("Add Promotional Goods")}
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        background:
                          "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                        pointerEvents: "none",
                      }}
                    ></div>
                  </Button>
                </Row>
                {promoItemsValidationError && (
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "red",
                      }}
                    >
                      Please fill all item details like in-unit/in sub-unit,
                      expiry date.
                    </Col>
                  </Row>
                )}
              </ModalBody>
            </div>
          </Modal>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(
  connect(null, {})(withTranslation()(AllPurchaseOrder))
)
